const common_en = {
    SIGN_IN_TITLE: 'Sign in to JetOS',
    ENTER_DETAIL: 'Enter your details below.',
    EMAIL_ADDRESS: 'Email Address',
    EMAIL: 'Email',
    ENTER_EMAIL: 'Enter email address',
    COMPANY_WEBSITE: 'Company Website',
    PASSWORD: 'Password',
    NEW_PASSWORD: 'New Password',
    FORGOT_PASSWORD: 'Forgot your password?',
    SIGN_IN: 'Sign In',
    SIGN_OUT: 'Sign Out',
    DONT_HAVE_ACCOUNT: "Don't have an account?",
    REQUEST_ACCOUNT: 'Request an account',
    REQUEST_ENTER_DETAIL:
        'Enter your details below to let us know more about you.',
    // User and Address
    MR: 'Mr.',
    MRS: 'Mrs.',
    NAME: 'Name',
    LAST_NAME: 'Last name',
    FIRST_NAME: 'First name',
    YOUR_LAST_NAME: 'Your last name',
    YOUR_FIRST_NAME: 'Your first name',
    ADDRESS: 'Address',
    ADDRESS_LINE_1: 'Address Line 1',
    ADDRESS_LINE_2: 'Address Line 2 (optional)',
    POST_CODE: 'Post Code',
    CITY: 'City',
    COUNTRY: 'Country',
    COUNTY: 'County',
    ACTION: 'Action',
    EDIT: 'Edit',
    CANCEL_EDIT: 'Cancel Edit',
    STATUS: 'Status',
    PROFILE_SETTINGS: 'Profile Settings',
    ABOUT: 'About',
    ABOUT_US: 'About Us',
    OUR_TEAM: 'Our Team',
    HOUR_OF_OPERATION: 'Hour of Operation',
    MAKE_A_RESERVATION: 'Make a reservation',
    PHOTOS: 'Photos',
    CHANGE_PHOTO: 'Change Photo',
    EXPAND_ALL_PHOTOS: 'Expand All Photos',
    ISSUE_DATE: 'Date of issue',
    EXPIRE_DATE: 'Date of expiration',
    IDENTIFICATION: 'Identification',
    TIMELINE: 'Timeline',
    CONNECTION: 'Connection',
    ALL_CONNECTION: 'All Connection',
    EDIT_PROFILE: 'Edit Profile',
    EDIT_INFORMATION: 'Edit Information',
    SEND_MESSAGE: 'Send Message',
    REQUEST_PARTPERSHIP: 'Request Partnership',
    CANCEL_PARTNERSHIP: 'Cancel Partnership',
    HEADQUATER: 'Headquarter',
    FOLLOW: 'Follow',
    FLOLLOWING: 'Following',
    LOCATIONS: 'Locations',
    AIRCRAFT_LISTING: 'Aircraft Listings',
    FLIGHT_POSTED: 'Flight Posted',
    FUEL_SERVICE: 'Fuel Service',
    PREFERENCES: 'Preferences',
    DOCUMENT_TYPE: 'Document Type',
    NUMBER: 'Number',
    BILLING_AND_PAYMENT: 'Billing And Payment',
    TYPE_OF_DOCUMENT: 'Type of document',
    DOCUMENT_NUMBER: 'Document Number',
    PRIMARY_PAYMENT: 'Primary Payment Method',
    PAYMENT_METHOD: 'Payment Method',
    CARD_HOLDER: 'Card Holder',
    CARD_NUMBER: 'Card Number',
    VALID_TO: 'Valid to',
    VALID_DATE: 'Valid Date',
    BILLING_ADDRESS: 'Billing Address',
    LOOKING_FOR_WORK: 'Looking for work',
    VISIBLE_TO_USERS: 'Visible To JetOS Users',
    PHONE_NUMBER: 'Phone number',
    FAX: 'Fax',
    MAINTENANCE: 'Maintenance',
    CATERING: 'Catering',
    COMPANY: 'Company',
    ROLE: 'Role',
    OPTIONAL: 'Optional',
    SUBMIT_REQUEST: 'Submit request',
    SUBMIT_NEW_AIRPORT: 'Submit New Airport',
    TITLE: 'Title',
    // Api error message
    NOT_LOGGED_IN: 'Unauthorized', // ask user to login
    UNAUTHORIZED: 'Unauthorized',
    INVALID_COMPANY_ID: 'Company not found', // ask user to login
    COULD_NOT_RETRIEVE_USER: 'User not found',
    USER_NOT_FOUND: 'User not found',
    USER_INACTIVE: 'User is inactive',
    INVALID_CREDENTIALS: 'Invalid email or password',
    ERROR_PASSWORD: 'Password is incorrect.',
    ERROR_ADDING_NOTES:
        'There was a problem adding notes. Please try again later.',
    COULD_NOT_RETRIEVE_AIRCRAFT: 'Aircraff not found',
    NOT_LOGGED_IN: '',
    OPERATOR_REQUIRED: 'Aircraf not found',
    RESULT_FOUND: 'result found',
    // Others
    DOCUMENT_INFORMATION: 'Document Information',
    CHOOSE_DOCUMENT_TYPE: 'Choose document type',
    UPLOAD_PHOTOS: 'Upload Photos',
    CHOOSE_FILE: 'CHOOSE FILE',
    NOTICE_CHOOSE_FILE:
        'For best result, please upload an image with 16:9 aspect ratio',
    ACTING_AS: 'Acting as',
    AVIAN_PAY: 'Avian Pay',
    SETTINGS: 'Settings',
    DASHBOARD: 'Dashboard',
    COMPANY: 'Company',
    FLIGHTS: 'Flights',
    BOOKINGS: 'Bookings',
    MESSAGES: 'Messages',
    COMMUNITY: 'Community',
    MARKETPLACE: 'Marketplace',
    AIRPORT: 'Airport',
    AIRPORTS: 'Airports',
    CUSTOMER: 'Customer',
    CUSTOMERS: 'Customers',
    EMPLOYEES: 'Employees',
    FLEET: 'Fleet',
    FLEETS: 'Fleets',
    MEMBERSHIP: 'Membership',
    MEMBERSHIPS: 'Memberships',
    COMPANY_PROFILE: 'Company Profile',
    EDIT_COMPANY_PROFILE: 'Edit Company Profile',
    CHANGE_COVER_IMAGE: 'Change Cover Image',
    CHANGE_PROFILE_IMAGE: 'Change Profile Image',
    ADD_NEW_TEAM_MEMBER: 'Add New Team Member',
    PARTNERS: 'Partners',
    PARTNER: 'Partner',
    PARTNERSHIP: 'Partnership',
    SERVCIES: 'Services',
    SERVICE: 'Service',
    SERVICE_ITEM_INFO: 'Service Item Info',
    ADD_SERVICE_ITEM: 'Add New Service Item',
    SERVICE_INFO: 'Service Information',
    SERVICE_ITEM_NAME: 'Service Item Name',
    AVAILABILITY: 'Availability',
    PRICE_PER_ITEM: 'Price per item',
    MAX_QUANTITY: 'Max quantity',
    PRICE_PER_SERVING: 'Price Per Serving',
    AVAILABLE_FOR_ALL_AIRCRAFTS: 'Available for all aircrafts',
    AVAILABLE_FOR_ALL_COUNTRIES: 'Available in all contries',
    CONFIRMED: 'Confirmed',
    CREATED: 'Created',
    CANCELLED: 'Cancelled',
    EXECUTED: 'Completed',
    AWAITING_CONFIRMATION: 'Awaiting Confirmation',
    TYPE: 'Type',
    CATEGORY: 'Category',
    DESCRIPTION: 'Description',
    PRICING: 'Pricing',
    AIRCRAFTS: 'Aircrafts',
    AIRCRAFT: 'Aircraft',
    VIEW_ALL_NOTIFICATIONS: 'View All Notifications',
    NOTIFICATION: 'Notification',
    MARK_ALL_AS_READ: 'Mark all as Read',
    MARK_AS_READ: 'Mark as Read',
    REMOVE_THIS_NOTI: 'Remove this notification',
    CHOOSE_AIRCRAFTS: 'Choose Aircrafts Available',
    SEARCH_AIRCRAFTS: 'Search Aircrafts...',
    SEARCH_COUNTRIES: 'Search Countries...',
    CHANGE_NAME: 'Change Name',
    CHANGE_DISPLAY_NAME: 'Change display name',
    DISPLAY_NAME: 'Display name',
    SHOW_NAME: 'Show Name',
    ADD_AVAILABLE_COUNTRIES: 'Add Available Countries',
    DISPUTES: 'Disputes',
    ORDERS: 'Orders',
    INVOICES: 'Invoices',
    SELECT_PAYMENT_METHOD: 'Select Payment Method',
    ADD_NEW_INVOICE: 'Add New Invoice',
    CUSTOMER_INVOICE: 'Customer Invoice',
    OPERATOR_INVOICE: 'Operator Invoice',
    INVOICE_SENT_FROM: 'Invoice Sent From: ',
    INVOICE_SENT_TO: 'Invoice Sent To: ',
    SUBCRIPTIONS: 'Subcriptions',
    PAYOUTS: 'Payouts',
    // Payment methods and description
    PAYOUT_INTRO: 'In order to collect payouts and do business on JetOS we must ask you to create a business account with our Payment Service Provider Stripe. Creating an account is free of charge and allows us to transfer money to your account automatically while upholding regulations.\u000APlease click the button below to connect with our Payment Service Provider, also if you already have an account with Stripe.',
    PAYMENT_MEDTHODS: 'Payment Methods',
    CONNECT_STRIPE: 'Connect Stripe',
    DIRECT_TRANSFER: 'Direct Transfer',
    DIRECT_TRANSFER_DESCRIPTION: 'Customer sent via bank transfer',
    CASH: 'Cash',
    PAYMENT_CASH_DESCRIPTION: 'Customer paid by cash',
    MEMBERSHIP_ACCOUNT: 'Membership Account',
    MEMBERSHIP_ACCOUNT_DESCRIPTION: 'Using Membership',
    OTHER: 'Other',
    PAYMENT_OTHER_DESCRIPTION: 'Other payment method',
    ENTER_PAYMENT_METHOD: 'Enter payment method',
    MY_INVOICES: 'My Invoices',
    CUSTOMER_INVOICES: 'Customer Invoices',
    MY_BOOKINGS: 'My Bookings',
    PARTNER_BOOKINGS: 'Partner Bookings',
    PARTNER_INVOICES: 'Partner Invoices',
    PARTNER_INVOICES_DETAIL: 'Partner Invoice Details',
    CUSTOMER_INVOICES_DETAIL: 'Customer Invoice Details',
    CUSTOMER_INFORMATION: 'Customer Information',
    GENERAL_INFO: 'General Information',
    SUBMIT_CUSTOMER_INFO: 'Submit Customer Info',
    SUBMIT_SERVICE_ITEM: 'Submit Service Item',
    BOOKING: 'Booking',
    CREATED_AT: 'Created At',
    UPDATED_AT: 'Updated At',
    DATE_CREATED: 'Date created',
    DATE_MODIFIED: 'Date modified',
    TYPE_OF_FLIGHT: 'Type Of Flight',
    FLIGHT_INFO: 'Flight Info',
    JET_INFO: 'Jet Info',
    PRICE: 'Price',
    BOOKING_STATUS: 'Booking Status',
    MEMBERSHIP_SINCE: 'Membership Since',
    UPLOAD_IDENTIFIACTION: 'Upload Identification',
    IDENTIFIACTION_UPLOADED: 'Identification Uploaded',
    UNLOCK_TO_VIEW: 'Unlock to view',
    CUSTOMER_HAVE_NO_IDEN: 'Customer have no identification',
    USER_HAVE_NO_IDEN: 'No identification',
    EMPLOYEE_HAVE_NO_IDEN: 'Employee have not uploaded identification',
    DATE_FORMAT_STR: 'Date Format',
    TIME_FORMAT: 'Time Format',
    PASSPORT: 'Passport',
    DRIVER_LICENSE: 'Driver License',
    ID_CARD: 'ID Card',
    LOOKING_FOR_OPERATOR: 'Looking for Operator',
    VISIBLE_TO_OPERATOR: 'Visible to Operator',
    PUSH_NOTIFICATION: 'Push Notification',
    SAVE_CHANGES: 'Save Changes',
    VIEW_EMPLOYEES_LIST: 'View employees list',
    ADD_NEW_EMPLOYEE: 'Add new employee',
    SEARCH: 'Search',
    SEARCH_KEYWORD: 'Search keyword',
    NEWSFEEDL: 'Newsfeed',
    JOB_POST: 'Job Post',
    BOOKINGS_SUBTITLE: 'Check your booking flight and booking history.',
    INVOICE_DETAILS: 'Invoice Details',
    VISIBLE_ADDRESSEE: 'Visible Addressee',
    FBO: 'FBO',
    LIST_OF_FBO: 'List of FBO',
    ADD_NEW_FBO: 'Add New FBO',
    FBO_INFO: 'FBO Information',
    FBO_STATUS_NEW: 'Created',
    FBO_STATUS_PENDING: 'Partnership Request Sent',
    FBO_STATUS_CONNECTED: 'Accepted',
    FBO_NAME: 'FBO Name',
    FBO_ADDRES_LINE_1: 'FBO Address Line 1',
    FBO_ADDRES_LINE_2: 'FBO Address Line 2 (Optional)',
    FBO_SUCCESSFULLY_ADDED: 'FBO Added Successfully!',
    SUBMIT_FBO_INFO: 'Submit FBO Info',
    LIST_OF_PARTNERS: 'List of partners',
    STATE: 'State',
    NAME: 'Name',
    ROLE: 'Role',
    VIEW_DETAIL: 'View Detail',
    VIEW_DETAILS: 'View Details',
    DETAILS: 'Details',
    REQUEST_SERVICES: 'Request Services',
    NO_DATA: 'No Data',
    NO_DATA_FBO: 'New FBO added. No data to show',
    FREQ: 'FREQ.',
    FREQUENCY: 'Frequency',
    ADMIN: 'Administrator',
    PILOT: 'Pilot',
    CREW: 'Crew',
    OPERATIONS: 'Operations',
    VIEW_ALL_CUSTOMER: 'View all customer',
    ADD_NEW_EMPLOYEE: 'Add new employee',
    ADD_NEW_CUSTOMER: 'Add new customer',
    EDIT_CUSTOMER: 'Edit Customer',
    EMPLOYEE_INFORMATION: 'Employee Information',
    UPLOAD_PHOTO: 'Upload photo',
    CHOOSE_A_ROLE: 'Choose a role',
    CHOOSE_TYPE: 'Choose type',
    ADMINISTRATION_CHECK: 'Administration included',
    DATE_OF_BIRTH: 'Date of birth',
    DATE_FORMAT: 'DD / MM / YYYY',
    ADDRESS_LINE_1: 'Address line 1',
    ADDRESS_LINE_2: 'Address line 2',
    POST_CODE: 'Post code',
    CITY: 'City',
    COUNTY: 'County',
    SUBMIT_EMPLOYEE_INFO: 'Submit employee info',
    SUBMIT: 'Submit',
    EMPLOYEE_SINCE: 'Employee Since',
    NONE: 'None',
    IDENTIFICATION: 'Identification',
    EDIT_INFORMATION: 'Edit information',
    SERVICE_ITEMS: 'Service Items',
    ITEMS: 'Items',
    TYPE: 'Type',
    SERVICE_ITEMS_FOR_TRIPS: 'Service item for trips',
    CREATE_NEW_ITEM: 'Create new item',
    OPERATOR_SERVICE: 'Operator service',
    AIRPORT_SERVICE: 'Airport service',
    AVAILABLE: 'Available',
    NOT_AVAILABLE: 'Not available',
    MAIN_DISH: 'Main dishes',
    STARTERS: 'Starters',
    WINE: 'Wines',
    DESSERTS: 'Desserts',
    EMPLOYEE_SUCCESSFULLY_ADDED: 'Employee Info Added Successfully!',
    EMAIL_EXISTS: 'This email was taken, please enter another.',
    CUSTOMER_SUCCESSFULLY_ADDED: 'Customer Info Added Successfully!',
    IDENTIFICATION_SUCCESSFULLY_UPLOAD: 'Upload Identification Successfully!',
    VIEW_CUSTOMER_LIST: 'View customer list',
    VIEW_FBO_LIST: 'View FBO List',
    SERVICE_SUCCESSFULLY_ADDED: 'Service Info Added Successfully!',
    VIEW_SERVICE_LIST: 'View service items list',
    WHITELABEL_UPDATE_SUCCESSFULLY: 'Whitelabel Setting Updated Successfully',
    VISIT_WHITELABEL: 'Visit Whitelabel',
    EMPLOYEE_SUCCESSFULLY_UPDATED: 'Employee Info Updated Successfully!',
    CUSTOMER_SUCCESSFULLY_UPDATED: 'Customer Info Updated Successfully!',
    SERVICE_SUCCESSFULLY_UPDATED: 'Service Info Updated Successfully!',
    COMPANY_PROFILE_SUCCESSFULLY_UPDATED:
        'Company Profile Updated Successfully!',
    AIRCRAFT_SUCCESSFULLY_UPDATED: 'Aircraft Info Updated Successfully',
    AIRCRAFT_SUCCESSFULLY_DELETED: 'Aircraft has been deleted successfully',
    ENTER_PASSWORD: 'Enter password',
    ENTER_PASSWORD_UPLOAD_IDENTIFICATION: 'Enter password to upload identification',
    ENTER_PASSWORD_UNCLOCK: 'Enter a password to unlock the identification',
    YOUR_FLEET: 'Your Fleet',
    PARTNERSHIP_FLEET: 'Partnership Fleet',
    BROKER_CONNECTIONS: 'Broker Connections',
    ADD_NEW_AIRCRAFT: 'Add new aircraft',
    SEARCH_BROKER_CONNECTIONS: 'Search aircraft, tail number,...',
    UPDATE_PROFILE_SETTING_SUCCESS: 'Profile Setting Updated Successfully!',
    ADD_NEW_TOP_RATED_SUCCESS: 'New Top Rated Airport Added Successfully!',
    DELETE_TOP_RATED_SUCCESS: 'Top Rated Airport Deleted Successfully!',
    DELETE_TOP_RATED_ERROR: 'Top Rated Airport Deleted Failed!',
    UPDATE_TOP_RATED_SUCCESS: 'Top Rated Airport Updated Successfully!',
    UPDATE_TOP_RATED_ERROR: 'Top Rated Airport Updated Failed!',
    DELETE_TOP_DESTINATION_SUCCESS: 'Top Destination Deleted Successfully!',
    DELETE_TOP_DESTINATION_ERROR: 'Top Destination Deleted Failed!',
    UPDATE_TOP_DESTINATION_SUCCESS: 'Top Destination Updated Successfully!',
    UPDATE_TOP_DESTINATION_ERROR: 'Top Destination Updated Failed!',
    ADD_NEW_TOP_RATED_ERROR: 'New Top Rated Airport Added Failed!',
    ADD_NEW_TOP_DESTINATION_SUCCESS: 'New Top Destination Added Successfully!',
    ADD_NEW_TOP_DESTINATION_ERROR: 'New Top Destination Added Failed!',
    CONFIRM_BOOKING_SUCCESS: 'Confirm Booking Successfully!',
    CONFIRM_BOOKING_FAILED: 'Confirm Booking Failed!',
    BOOKING_HAVE_BEEN_CONFIRMED: 'Booking has been confirmed.',
    ITINERARIES: 'Itineraries',
    AWAITING_PAYMENT: 'Awaiting Payment',
    NEW_BOOKING: 'New Booking',
    ALL_BOOKING: 'All Booking',
    BOOKING_CONFIRMED: 'Booking Confirmed',
    BOOKING_CANCELLED: 'Booking Cancelled',
    SEARCH_BY: 'Search By',
    RESULTS: 'Results',
    ALL: 'All Categories',
    PLACEHOLDER_SEARCH_BOOKING: 'Enter flight number, aircraft name tail,…',
    ADD_CREW: 'Add Crew',
    VIEW_JET_PROFILE: 'View Jet Profile',
    OPERATOR_FEES: 'Operator fees',
    AIRPORT_FESS: 'Airport fees',
    CATERING_AND_CHAMPAGGE: 'Catering and Champagne',
    POSITIONING_FEE: 'Positioning Fee',
    REVENUE_FEE: 'Revenue Fee',
    LANDING_FEE: 'Landing Fee',
    PARKING_FEE: 'Parking Fee',
    DEPARTURE_FEE: 'Departure Fee',
    SHORT_TERM_BOOKING_FEE: 'Short term Booking Fee',
    MARK_AS_PAID: 'Mark as Paid',
    NON_VISIBLE: 'Non Visible',
    VISIBLE: 'Visible',
    STATUS_INVOICE_PAYMENT_RECEIVED:
        'Payment received! Flight is waiting for confirmation.',
    BOOKING_CANCEL_IN: 'This booking will be cancel in ',
    MULTI_LEG: 'Multi Leg',
    ADD_PRICE: 'Add Price',
    ADD_NEW_SERVICE_ITEM: 'Add New Service Item',
    EDIT_SERIVCE_ITEM: 'Edit Service Item',
    VIEW_ITINERARIES: 'View Itineraries',
    CONFIRM_REMOVE_ITEM: 'Do you want to remove item?',
    CONFIRM_DELETE_AIRPORT: 'Do you want to delete airport?',
    CONFIRM_REMOVE_CREW: 'Do you want to remove crew?',
    CONFIRM_DELETE_EMPLOYEE: 'Do you want to delete employee?',
    CONFIRM_DELETE_EMPLOYEE_SUCCESS: 'Delete Employee Successfully!',
    CONFIRM_DELETE_EMPLOYEE_ERROR: 'Delete Employee Failed!',
    CONFIRM_DELETE_SERVICE: 'Do you want to delete service item?',
    CONFIRM_DELETE_SERVICE_SUCCESS: 'Delete Service Item Successfully!',
    CONFIRM_DELETE_SERVICE_ERROR: 'Delete Service Item Failed!',
    WHITELABEL: 'Whitelabel',
    WHITELABELS: 'Whitelabels',
    NO_WHITELABEL: 'No whitelabels added.',
    SETUP_WHITELABEL: 'Set Up Whitelabel',
    CREATE_NEW_WHITELABEL: 'Create new Whitelabel',
    ACTIVE_WHITELABEL: 'Active Whitelabel',
    WHITELABEL_NAME: 'Whitelabel name',
    INTRODUCTION: 'Introduction',
    WRITE_DESCRIPTION: 'Write description...',
    GENERAL_SETTINGS: 'General Settings',
    REDIRECT: 'REDIRECT TO COMPANY WEBSITE',
    REQUIRE_MEMBERSHIP: 'Require Membership',
    HIDE_TAILNUMBER: 'Hide Aircraft Tail Number',
    SHOW_SCHEDULED_FLIGHT: 'SHOW SCHEDULED FLIGHTS',
    SHOW_AVAILABLE_AIRCRAFTS: 'SHOW ALL AVAILABLE AIRCRAFTS',
    SHOW_ROUTES: 'SHOW ROUTES',
    APPEARANCE: 'Appearance',
    DOMAIN: 'Domain',
    URL: 'URL',
    DEFAULT_URL: 'Default URL',
    CUSTOM_URL: 'Custom URL',
    HTTPS: 'https://',
    ADD_LOGO: 'Add Company Logos',
    COMPANY_LOGO: 'Company Logo',
    FAVICON: 'Favicon',
    FAVICON_DESCRIPTION: 'Icon must be square by .png, .ico format',
    FAVICON_SUGGESTION: 'A favicon is a small icon next to your site title on your browser. It’s a great way to help visitors recognize your brand and stand out in the tabs.',
    COMPANY_LOGO_DESCRIPTION: 'Recommend logo ratio 4:3, 16:9',
    UPLOAD_FILE: 'Upload file',
    UPLOAD_LEGAL_FILE: 'Upload legal file',
    UPLOAD_TERMS_AND_CONDITIONS: 'Upload terms and conditions',
    UPLOAD_CHARTER_AGREEMENTS: 'Upload charter agreements',
    COLOR_AND_STYLE: 'Colors and Styles',
    TEXT_STYLE: 'Text Style',
    FONT_SELECTION_INTRO: 'Select font family. Recommend your brand font',
    SELECT_FONT: 'Select font',
    PRIMARY_FONT: 'Primary font family',
    SECONDARY_FONT: 'Secondary font family',
    BACKGOUND_COLOR: 'Component background color',
    TEXT_COLOR: 'Component text color',
    BUTTON_COLOR: 'Button background color',
    BUTTON_TEXT_COLOR: 'Button text color',
    HIGHLIGHT_COLOR: 'Highlight color',
    PRIMARY_COLOR: 'Primary color',
    SECONDARY_COLOR: 'Secondary color',
    HEADER_BACKGROUND_COLOR: 'Header Background',
    FOOTER_BACKGROUND_COLOR: 'Footer Background',
    LIGHT_BACKGROUND_COLOR: 'Light background color',
    SECONDARY_LIGHT_BACKGROUND_COLOR: 'Secondary light background color',
    BACKGROUND: 'Background',
    SHOW_BACKGROUND_IMAGE: 'Show Background Image',
    UPLOAD_DOCUMENT: 'Upload Documents',
    DOCUMENT_NAME: 'Document Name',
    ENTER_DOCUMENT_NAME: 'Enter document name',
    COMPONENT_HERO_IMAGE: 'Components - Hero Image',
    HERO_IMAGE: 'Hero Image',
    CUSTOM_TEXT: 'Custom Text',
    HERO_IMAGE_DESCRIPTION: 'Upload photo for main picture at homepage',
    CUSTOM_TEXT_DESCRIPTION: 'Edit Title',
    MAIN_TITLE: 'Main Title',
    SUB_TITLE: 'Sub-title',
    ENTER_TITLE: 'Enter your title',
    ENTER_SUB_TITLE: 'Enter your sub-title',
    COMPONENT_ADVERTISEMENT: 'Components - Advertisement Section',
    SHOW_SECTION: 'Show Section',
    UPLOAD_BACKGROUND: 'Upload Background',
    BACKGROUND_IMAGE: 'Background Image',
    BACKGROUND_IMAGE_DESCRIPTION: 'Upload Image BG',
    APP_IMAGE_DESCRIPTION: 'Upload App showcase, screenshot. Recommend using .png file. Ratio 4:3, 16:9',
    UPLOAD_PHOTO_OR_DRAG: 'Upload Photo or Drag and Drop here',
    SHORT_DESCRIPTION: 'Short Description',
    COMPONENT_APP_INTRO: 'Components - App Intro',
    APP_INTRO: 'App Intro',
    CUSTOM_BUTTON: 'Custom Button',
    CUSTOM_BUTTON_DESCIPTION: 'Select Button to show',
    ADD_LINK: 'Add Link',
    ENTER_APP_LINK: 'Enter link to your app',
    APP_STORE: 'App Store',
    GOOGLE_PLAY: 'Google Play',
    DOWNLOAD_APP: 'Download on the',
    COMPONENT_FOOTER: 'Components - Footer',
    ADD_COMPANY_SOCIAL_PROFILE: 'Add Your Company Social Profile',
    FACEBOOK: 'Facebook Page',
    TWITTER: 'Twitter',
    INSTAGRAM: 'Instagram',
    LINKEDIN: 'Linkedin',
    PUBLISH: 'Publish',
    PREVIEW: 'Preview',
    YES_REMOVE_IT: 'Yes, Remove it',
    NO_DISREGARD: 'No, Disregard',
    PAGE_ABOUT_US: 'Page - About Us',
    PAGE_CONTACT_US: 'Page - Contact Us',
    ABOUT_US_INTRO: 'Write a story and introduce customer about your company',
    CONTACT_US_INTRO: 'Add short description to guide customer how to contact your company',
    CONTACT_INFO: 'Contact Info',
    ADD_COMPANY_EMAIL: 'Add company email',
    PHONE_HOTLINE: 'Phone/Hotline',
    ADD_PHONE_HOTLINE: 'Add company phone/hotline',
    INVOICE_NUMBER: 'Invoice Number',
    BOOKING_NUMBER: 'Booking Number',
    DUE_DATE: 'Due Date',
    INVOICE_TOTAL: 'Invoice Total',

    PAYMENT_STATUS: 'Payment Status',
    PAYMENT_AMOUNT: 'Payment Amount',
    BROKER: 'Broker',
    PARTNER: 'Partner',
    ORIGINAL_BOOKER: 'Original Booker',
    PAID: 'Paid',
    AWAITING_REFUND: 'Awaiting Refund',
    VIEW_BOOKING: 'View Booking',
    VIEW_INVOICE: 'View Invoice',
    VIEW_PACKAGE_INVOICE: 'View Package Invoice',
    SEND_INVOICE: 'Send Invoice',
    CONFIRM_BOOKING: 'Confirm Booking',
    VIEW_ITINERARY: 'View Itinerary',
    TOTAL_EX_VAT: 'Total Ex VAT',
    VAT: 'VAT',
    TOTAL_ICN_VAT: 'Total Icn.VAT',
    TOTAL_PAYOUT: 'Total Payout',
    PAYOUT: 'Payout',
    CUSTOMER_PAYOUT: 'Customer Payout',
    OPERATOR_RECEIVED: 'Operator Received',
    BOOKING_FEE: 'Booking Fee',
    BROKER_RECEIVED: 'Broker Received',
    ADMINISTRATOR_FEE: 'Administrator Fee',
    SALE_INFO: 'Sale Information',
    NO_SALE_INFO: 'No Sale Information',
    NO_ITEM_ADDED: 'No Item Added',
    NO_LUGGAGE_ADDED: 'No luggage added.',
    FLIGHT_NOTES: 'Flight Notes',
    SPECIAL_REQUEST: 'Special Request',
    CREW_INFO: 'Crew Info',
    CONNECT_FLIGHT: 'Connect Flights',
    LUGGAGES_WEIGHT: 'Luggages Weight',
    PASSENGERS_INFO: 'Passengers Info',
    TRAVEL_PREFERENCES: 'Travel Preferences',
    LUGGAGE_SIZE_SMALL: 'Luggage weight < 20lb',
    LUGGAGE_SIZE_MEDIUM: 'Luggage weight 20lb - 40lb',
    LUGGAGE_SIZE_LARGE: 'Luggage weight 40lb - 60lb',
    LUGGAGE_SIZE_GOLF_CLUBS: 'Golf bag weight 25lb - 35lb',
    SMALL: 'Small',
    MEDIUM: 'Medium',
    LARGE: 'Large',
    GOLF_CLUBS: 'Golf Club',
    BOOKING_TYPE: 'Booking Type',
    BOOKED_BY: 'Booked By',
    DEPARTURE_FBO_INFO: 'Departure FBO',
    ARRIVAL_FBO_INFO: 'Arrival FBO',
    SELECT_FBO: 'Select FBO',
    ADD_NOTES: 'Add Notes',
    SELECT_AIRCRAFT_FOR: 'Select aircraft for ',
    PACKAGE: 'Package',
    CREATE_PACKAGE: 'Create Package',
    PACKAGE_INVOICE: 'Package Invoice',
    CREATE_PACKAGE_QUOTATIONS: 'Create Package of Quotations',
    INVOICES_ITEM: 'Invoice Items',
    VIEW_SUMMARY: 'View Summary',
    ADD_TO_PACKAGE: 'Add To Package',
    TOTAL_PRICE: 'Total Price',
    NON_MEMBER_SURCHARGE: 'Non-Member Surcharge',
    CREATE_QUOTATION_PACKAGE: 'Create Quotation Package',
    OPERATOR_BY: 'Operator by ',
    ENTER_NAME_ITEMS: 'Enter Name Of Service Item',
    ENTER_NAME_EXTRA_FEE: 'Enter Name Of Extra Fee',
    INVOICE_SENT_TO: 'Invoice Sent To: ',
    QUOTE: 'Quote',
    EXTRA_FEE: 'Extra Fee',

    FILTER: 'Filter',
    BY_CATEGORY: 'By category',
    BY_OPERATOR: 'By operator',
    OPERATOR: 'Operator',
    HOUR_RATES: 'Hourly Rates',
    HOURLY_RATES_DOLLAR: 'Hourly rates ($)',
    APPLY_FILTER: 'Apply filter',
    CLEAR_FILTER: 'Clear filter',
    HOURLY_RATES: 'Hourly rates:',
    OVERNIGHT_FEES: 'Overnight fees:',
    OVERNIGHT_FEES_DOLLAR: 'Overnight fees ($)',
    DAILY_MINIMUM: 'Daily minimum',
    DAILY_MINIMUM_HOURS: 'Daily minimum (hours)',
    LEGROOM: 'Legroom',
    VIDEO_MONITOR: 'Video Monitor',
    GRANITE_COUNTERS: 'Granite Counters',
    AIRCRAFT_TYPE: 'Aircraft Type',
    YEAR: 'Year',
    LAST_REFURBISHED: 'Last Refurbished',
    NUMBER_OF_SEATS: 'Number of seats',
    CRUISE_SPEED: 'Cruise Speed',
    RANGE: 'Range',
    LUGGAGE_CAPACITY: 'Luggage Capacity',
    FEATURED_AMENITIES: 'Featured Amenities',
    CREATE_QUOTE: 'Create Quote',
    EXTRA_LEG_ROOM: 'Extra Leg Room',
    LAVATORY: 'Lavatory',
    LAVATORIES: 'Lavatories',
    WIFI: 'Wifi',
    SLEEPS: 'Sleeps',
    SINK_AND_LIGHT: 'Sinks & Mirrored Vanity Light',
    AIRCRAFT_DETAIL: 'Aircraft Details',
    ESTIMATE_AIRCRAFT: 'Estimate Aircraft',

    CLIMB_MILES: 'Climb Miles',
    CLIMB_SPEED_MPH: 'Climb Speed Mph',
    CLIMB_TIME_MINUTES: 'Climb Time Minutes',
    CRUISING_DISTANCE_MILES: 'Cruising Distance Miles',
    CRUISING_SPEED_MPH: 'Cruising Speed Mph',
    CRUISING_TIME_MINUTES: 'Cruising Time Minutes',
    DESCENT_MILES: 'Descent Miles',
    DESCENT_SPEED_MPH: 'Descent Speed Mph',
    DESCENT_TIME_MINUTES: 'Descent Time Minutes',
    TOTAL_FLIGHT_TIME_MINUTES: 'Total Flight Time Minutes',
    REVENUE_FEE: 'Revenue Fee',
    BOOKING_FEE_TOTAL: 'Booking Fee Total',
    TOTAL_EX_VAT: 'Total Ex Vat',
    TOTAL_INC_VAT: 'Total Inc Vat',
    TOTAL_VAT: 'Total Vat',
    KM: 'Km',
    MI: 'Mi',
    CLIMB_CRUISING_DESCENT: 'Climb Cruising Descent',
    DISTANCE: 'Distance',
    PRICE: 'Price',
    TO_AIRPORT_DIFFERENT_FROM_AIRPORT: 'To airport must be different from airport',

    OPERATED_BY: 'Operated by',
    HOME_PAGE_AIRPORT: 'Home base airport ',
    LOAD_MORE: 'Load more',
    WRITE_SOME_NOTES: 'Write some notes',
    // Aircraft categories
    CLASS_HEAVY: 'Heavy',
    CLASS_MIDSIZE: 'Midsize',
    CLASS_LIGHT: 'Light',
    CLASS_TURBO_PROPS: 'Turboprops',
    CLASS_TWIN_PISTONS: 'Twin pistons',
    CLASS_JUMBO: 'Jumbo',
    CLASS_SUPER_MIDSIZE: 'Super midsize',
    CLASS_AIRTAXI: 'Air Taxi',
    CLASS_VERY_LIGHT: 'Very light',
    CLASS_VIP_AIRLINER: 'VIP airliner',
    CLASS_LONG_RANGE_JET: 'Long range',
    SEARCH_AIRCRAFTS_AND_TAIL_NUMBER: 'Select Aircraft Category',
    RESULT_FOUND: 'result found',
    PROPERTIES: 'Properties',
    AIRCRAFT_PROPERTIES: 'Aircraft Properties',
    PASSENGERS: 'Passengers',
    MAX_PASSENGERS: 'Max. Passenger',
    HEIGHT: 'Height (FT.)',
    LENGTH: 'Length (FT.)',
    WINGSPAN: 'Wingspan (FT.)',
    WING_AREA: 'Wing area (FT\u00B2 .)',
    EMPTY_WEIGHT: 'Empty weight (LB.)',
    MAX_TAKEOFF_WEIGHT: 'Max. takeoff weight (LB.)',
    LUGGAGE_CAPACITY_3: 'Luggage capacity (FT\u00B3. )',
    PERFORMANCE_PROPERTIES: 'Performance Properties',
    MAX_RANGE_IN_NAUTICAL_MILES: 'Max. range in nautical miles',
    AVERAGE_SPEED: 'AVERAGE SPEED (MPH.)',
    CRUISING_ALTITUDE: 'Cruising altitude (FT.)',
    CRUISING_SPEED_IN_KNOTS: 'Cruising speed in knots',
    CLIMB_RATE: 'Climb rate (FPM.)',
    DESCENT_RATE: 'Descent rate (FPM.)',
    CLIMB_SPEED_IN_KNOTS: 'Climb speed in knots',
    DESCENT_SPEED_IN_KNOTS: 'Descent speed in knots',
    TAKEOFF_DISTANCE: 'Takeoff distance (FT.)',
    LANDING_DISTANCE: 'Landing distance (FT.)',
    AMENITIES: 'Amenities',
    BED: 'Bed',
    SINK: 'Sink',
    MODEL: 'Model',
    PRICE_PARAMETERS: 'Price parameters',
    GENERAL: 'General',
    SHORT_TERM_PRICE_INCREMENT: 'Short Term Price Increment (%)',
    DEFAULT_AIRPORT_FEES: 'Default Airport Fees',
    TAX: 'TAX (%)',
    MIN_BOOKING_TIME: 'Min. booking time (HRS)',
    GROUND_TIME_MINUTES: 'Ground time (MINUTES)',
    POSITION_FEE_PER_HOUR: 'Position fee per hour',
    REVENUE_FEE_PER_HOUR: 'Revenue fee per hour',
    UNDER_6_HOURS: '< 6HRS',
    FROM_6HRS_TO_UNDER_12HRS: 'From 6HRS to under 12HRS',
    FROM_12HRS_TO_UNDER_24HRS: 'From 12HRS to under 24HRS',
    FROM_24HRS_TO_UNDER_48HRS: 'From 24HRS to under 48HRS',
    LANDING_FEE: 'Landing fee',
    DEPARTURE_FEE: 'Departure fee',
    PARKING_FEE: 'Parking fee',
    OVERNIGHT_STAY_FEE: 'Overnight stay fee',
    LOCATION: 'Location',
    HOME_AIRPORT: 'Home airport',
    CURRENT_AIRPORT: 'Current Airport',
    PRICE_REFLECTION_AIRPORT: 'Price reflection airport',
    CURRENT_LOCATION: 'Current location',
    UPLOAD_MORE_PHOTOS: 'Upload more photos',
    VIEW_AIRCRAFTS_LIST: 'View aircrafts list',
    // aircraft
    // SEARCH_AIRCRAFTS_AND_TAIL_NUMBER: 'Search aircraft, tail number,...',
    ADD_AIRCRAFT: 'Add Aircraft',
    GENERIC_AIRCRAFT: 'Generic aircraft',
    GENERIC_DESCRIPTION:
        'Select an aircraft from our catalogue to pre-fill all specifications or enter new aircraft name.',
    SELECT_GENERIC_AIRCRAFT: 'Select generic aircraft',
    AIRCRAFT_NAME: 'Aircraft name',
    ENTER_AIRCRAFT_NAME: 'Enter aircraft name',
    TAIL_NUMBER: 'Tail number',
    ENTER_TAIL_NUMBER: 'Enter tail number',
    ENTER_OPERATOR_NAME: 'Enter operator name',
    AIRCRAFT_CATEGORY: 'Aircraft Category',
    SELECT_AIRCRAFT_CATEGORY: 'Select aircraft category',
    MANUFACTURER: 'Manufacturer',
    NAME_OF_MANUFACTURER: 'Name of manufacturer',
    YEAR_OF_MANUFACTURE: 'Year of Manufacture',
    DATE: 'Date',
    AMOUNT: 'Amount',
    DESCRIBE_AIRCRAFT: 'Describe about aircraft',
    UPLOAD_AIRCRAFT_PHOTOS: 'Upload Aircraft Photos',
    UPLOAD_FORMAT: 'Upload photo in .jpg, .png formats',
    UPLOAD_FILE_DRAG_DROP: 'Upload file or drag and drop',
    AIRCRAFT_INFO: 'Aircraft Information',
    SUBMIT_AIRCRAFT_INFO: 'Submit aircraft info',
    VIEW_AIRCRAFT_DETAILS: 'View aircraft details',
    SUBMMITED_SUCCESSFULLY: 'Summited Succesfully!',
    NEW_AIRCRAFT_ADDED: 'New aircraft has been added to fleet connection',
    OK: 'Ok',
    QUOTE_REQUEST: 'Quote Request',
    QUOTE_REQUESTS: 'Quote Requests',
    ITINERARIES: 'Itineraries',
    ONEWAY: 'Oneway',
    FLIGHT_TYPE: 'Flight Type',
    REQUEST_NUMBER: 'Request #',
    REQUEST_SEND_BY: 'Request Sent By',
    REQUEST_SENT_AT: 'Request sent at',
    REQUEST_STATUS: 'Request Status',
    EXPIRED: 'Expired',
    AIRCRAFT_TYPE_REQUEST: 'Aircraft Type Request',
    PAX: 'Pax',
    CUSTOM_SERVICE: 'Custom service',
    OPERATOR_PAYOUT: 'Operator Payout',
    CUSTOM_SERVICE_ITEMS: 'Custom service items',
    NEW_QUOTE_REQUEST: 'New quote request',
    AWAITING_CUSTOMER_RESPONSE: 'Awaiting customer response',
    QUOTE_ACCEPTED: 'Quote accepted',
    DECLINED: 'Declined',
    REQUESTED_BY: 'Requested By',
    ACTIVE: 'Awaiting Quotation',
    WAITING: 'Awaiting response',
    PRICE_RANGE: 'Price Range',
    COMPLETED: 'Completed',
    DEPARTURE: 'Departure',
    ARRIVAL: 'Arrival',
    RETURN: 'Return',
    ADD_MORE_ITEMS: 'Add more items',
    ADD_EXTRA_FEE: 'Add Extra Fee',
    LIST_ALL_INVOICE: 'List all invoices',
    VIEW_ALL_NOTIFICATION: 'View All Notification',
    DATE_CREATED: 'Date Created',
    INVOICE_CREATE_AT: 'Invoice Create At',
    INVOICE_STATUS: 'Invoice Status',
    TOTAL: 'Total',
    INVOICE: 'Invoice',
    ALL_NOTIFICATION: 'All Notification',
    CHANGE_DISPLAY_IMAGE: 'Change Display Image',
    REPORT_AND_IMPROVEMENT: 'Report & Improvement',
    COPYRIGHT_TEXT: 'Copyright text',
    COPYRIGHT_AVIAN: 'Copyright @2020 AvianJets.com',
    // Notification title
    NOTI_FLIGHT_EXECUTED: 'Flight Complete!',
    NOTI_FLIGHT_UPCOMING: 'Upcoming Flight',
    NOTI_FLIGHT_MISSING_FBO: 'Missing FBO Information',
    NOTI_FLIGHT_MISSING_PILOT: 'Missing Pilot Information',
    NOTI_FLIGHT_DELAY: 'Flight Delayed',
    NOTI_FLIGHT_UPDATED: 'Flight Info Updated',
    NOTI_BOOKING_RECEIVED: 'New Booking Received',
    NOTI_BOOKING_EXECUTED: 'Booking Complete!',
    NOTI_BOOKING_CONFIRMED: 'Booking Confirmed!',
    NOTI_BOOKING_REQUEST_SERVICE: 'Service Items Request',
    NOTI_BOOKING_CANCELLED: 'Booking Cancelled',
    NOTI_BOOKING_AWAITING_CONFIRMATION: 'Awaiting Confirmation',
    NOTI_QUOTEREQUEST_RECEIVED: 'New Quote Request Received',
    NOTI_QUOTEREQUEST_COMPLETED: 'Quote Request Complete!',
    NOTI_INVOICE_SENT: 'New Invoice Sent',
    NOTI_INVOICE_PAID: 'Invoice Paid!',
    CREATE_QUOTE_REQUEST: 'New Quote Request',
    UPDATE_QUOTE_REQUEST: 'Quote Request Updated',
    CREATE_BOOKING: 'New Booking',
    UPDATE_BOOKING: 'New Booking Updated',
    CREATE_FLIGHT: 'New Flight',
    UPDATE_FLIGHT: 'Flight Updated',
    CREATE_INVOICE: 'New Invoice',
    UPDATE_INVOICE: 'Invoice Updated',
    PROMOTIONS: 'Promotions',
    PROMOTIONS_DESCRIPTION: 'Top destionations and airport with special offer',
    TOP_RATED_AIRPORTS: 'Top Rated Airports',
    TOP_DESTINATIONS: 'Top Destinations',
    SPECAIL_ROUTES: 'Special Routes',
    SEARCH_TOP_RATED_AIRPORTS: 'Search airport name, code, locations...',
    SEARCH_TOP_DESTINATIONS: 'Search destination...',
    DISPLAY_PHOTO: 'Display Photo',
    AIRPORT_NAME: 'Airport Name',
    ICAO_IATA_CODE: 'ICAO/IATA Code',
    DISPLAY: 'Display',
    ADD_NEW_AIRPORT: 'Add New Airport',
    ADD_NEW_DESTINATION: 'Add New Destination',
    ENABLE: 'Enable',
    DISABLE: 'Disable',
    DESTINATION: 'Destination',
    AIRPORT_INFO: 'Airport Info',

    CANCEL_REQUEST: 'Cancel request',
    CREATE_QUOTATION: 'Create Quotation',
    PASSENGER_INFO: 'Passenger info',
    FLIGHT_NOTES: 'Flight notes',
    WRITE_NOTES: 'Write some notes',
    ADD_NOTES: 'Add notes',
    SELECT_AIRCRAFT: 'Select Aircraft',
    CATERING: 'Catering',
    SERVICE_ITEM_SELECTED: 'Service Item Selected',
    CUSTOM_SERVICE_ITEM: 'Custom Service',
    TOTAL_VAT: 'Total VAT',
    BROKER_FEE: 'Broker Fee',
    FINISH: 'Finish',
    ITEM: 'Item',
    SPECIAL_NOTES: 'Special Notes',
    QTT: 'Qtt',
    ACCEPT_REQUEST: 'Accept Request',
    QUOTES_CREATED: 'Quotes Created',
    ADD_TO_LIST: 'Add to list',
    GO_NEXT: 'Go next',
    NUMBER_OF_PASSENGER: 'Passenger',
    AIRCRAFTS_ADDED: 'Aircrafts Selected to Add to Quotation',
    GO_BACK: 'Go Back',
    QUOTE_CREATED_BY: 'Quotes Created By',
    SEND_QUOTES: 'Send quotes',
    NO_CUSTOM_SERVICE_ADDED: 'No custom service added',
    NO_SERVICE_ADDED: 'No service added',
    AIRCRAFT_SELECTED: 'Aircraft Selected',
    SEARCH_AIRCRAFT_NAME: 'Search aircraft name',
    UPCOMING_FLIGHTS: 'Upcoming Flights',
    SCHEDULED_FLIGHTS: 'Scheduled Flights',
    EMPTY_LEGS: 'Empty Legs',
    PROMOTIONS: 'Promotions',
    TOP_AIRPORT_DETAIL_SUBTITLE: 'Top Rated Aiport Detail',
    TOP_DESTINATION_DETAIL_SUBTITLE: 'Top Destination Detail',
    DESTINATION_INFO: 'Destination Info',
    FLIGHT_HISTORY: 'Flight History',
    DEPARTURE_TIME: 'Depart Time',
    FROM_TO: 'from-to',
    IN_FLIGHT: ' In-flights',
    LANDED_AT: 'Landed at',
    EMPTY_LEG_FLIGHT: 'Empty leg flights',
    FLIGHT: 'Flight',
    EMPTY_LEG: 'Empty leg',
    FLIGHT_STATUS: 'Flight Status',
    NO_DESCRIPTION: 'No description to show',
    NO_PASSENGER: 'No passenger added.',
    CONFIRM_FLIGHT: 'Confirm flight',
    CALENDAR: 'Calendar',
    VIEW_ALL: 'View all',
    VIEW_ALL_REQUESTS: 'View All Requests',
    LATEST_BOOKINGS: 'Latest Bookings',
    VIEW_ALL_BOOKINGS: 'View all bookings',
    OUR_FLEET: 'Our Fleet',
    PARTNER_FLEET: 'Partner Fleet',
    ALL_OPERATOR: 'All Operators',
    COMPANY_FLEET: 'Company Fleet',
    JETOS_PARTNERS: 'JetOS Partners',
    EXTERNAL_PARTNERS: 'External Partners',
    CANCEL_REQUEST_INTERROGATIVE:
        'Are you sure you want to cancel this quote request?',
    THIS_CANT_BE_UNDONE: "This can't be undone",
    YES_IM_SURE: "Yes, I'm sure",
    NO: 'No',
    WRITE_NOTE_TO_CUSTOMER: 'Write note to customer (optional)',
    QUOTE_DECLINED: 'Quotation has been declined by ',
    CONFIRM_PASSWORD: 'Confirm password',
    ACTIVATE_ACCOUNT: 'Activate Account',
    WELCOME_JETOS: 'Welcome to JetOS',
    INSTRUCTION_ACTIVATE_ACCOUNT:
        'To activate your account, please set your login password.',
    INSTRUCTION_RESET_PASSWORD:
        'To reset your password, please set a new password and confirm it.',
    PASSWORD_AT_LEAST_8_CHARACTERS:
        'Your password must be at least 8 characters',
    PASSWORD_AT_LEAST_CONTAIN_1_LETTER:
        'Your password must contain at least one letter.',
    PASSWORD_AT_LEAST_CONTAIN_1_DIGIT:
        'Your password must contain at least one digit.',
    CONFIRM_PASSWORD_DOES_NOT_MATCH: 'Confirm password does not match.',
    ERROR_ACCOUNT_VALIDATION:
        'There is an error occur, please contact the administrator',
    ACTIVATION_EXPIRED: 'The activation request is expired',
    RESET_PASSWORD_EXPIRED: 'The reset password request is expired',
    FORGOT_PASSWORD_INSTRUCTION:
        'Enter your email address or phone number, we\'ll send you a verify code to reset your password.',
    VIA_EMAIL: 'Via Email',
    VIA_SMS: 'Via Sms',
    SEND_CODE: 'SEND CODE',
    INVALID_EMAIL: 'Please enter a valid email',
    INVALID_PHONE: 'Please enter phone number',
    INVALID_COUNTRY: 'Please select country',
    ENTER_6_DIGITS: 'Enter the 6-digits code sent to you \nat',
    VERIFICATION: 'Verification',
    PHONE_EXISTED: 'Phone number is existed. Try another number.',
    CAN_NOT_SEND_SMS: 'Send SMS failed.',
    CAN_NOT_SEND_OTP: 'Send OTP failed.',
    ERROR_OTP_INVALID_CODE: 'Invalid OTP Code.',
    INVALID_OTP_CODE: 'Invalid OTP Code. You have the last times trial.',
    ERROR_OTP_CAN_NOT_GET_VERIFICATION_CHECK:
        'Invalid OTP Code. Try another code',
    CODE_EXPIRED: 'OTP expired',
    TOO_LATE: 'Your password reset link has expired.',

    CONTINUE: 'Continue',
    INCORRECT_EMAIL: 'Your email is incorrect',
    USER_NOT_FOUND: 'User is not found',
    ALREADY_REQUESTED: 'Your already requested',
    HAVE_SENT_EMAIL:
        'We have sent an email to you. Please follow instructions to reset your password.',
    BACK_TO_LOGIN: 'Back To Login',
    HI: 'Hi',
    RESET: 'Reset',
    TRY_AGAIN: 'Try Again',
    ENTER_YOUR_EMAIL_ADDRESS: 'Enter your email address',
    SELECT_COUNTRY: 'Select Country',
    COMPANY_NAME: 'Company name',
    THANK_YOU_REGISTER: 'Thank you for joining us',
    CONTACT_SOON: 'We will contact you soon',
    DELETE_AIRCRAFT_CONFIRMATION: 'Do you want to remove this aircraft?',
    WHITELABEL_STATUS: 'Whitelabel status',
    AVATAR: 'Avatar',
    DISABLE_WHITELABEL_CONFIRMATION: 'Do you want to disable',
    ENABLE_WHITELABEL_CONFIRMATION: 'Do you want to enable',
    YES_DISABLE_IT: 'Yes, Disable it',
    YES_ENABLE_IT: 'Yes, Enable it',
};

export default common_en;
