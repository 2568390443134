import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { template as tmpl } from './RequestAccountCard.tmpl';

const RequestAccountCardLogic = (props) => {
    const [gender, setGender] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [company, setCompany] = useState('');
    const [visibleModal, setVisibleModal] = useState(false);
    const initProps = {
        visibleModal,
        setVisibleModal,
        gender,
        setGender,
        firstname,
        setFirstname,
        lastname,
        setLastname,
        email,
        setEmail,
        country,
        setCountry,
        company,
        setCompany,
        phoneNumber,
        setPhoneNumber,
        ...props
    };
    return tmpl && tmpl({ ...initProps });
};

class RequestAccountCard extends React.PureComponent {
    render() {
        const initProps = {
            title: 'Login'
        };
        return <RequestAccountCardLogic {...initProps} />;
    }
}

export { RequestAccountCard };
