import React from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Card, Form, Input, Row, Col, Radio } from 'antd';
import { Input as CustomInput, Popup } from 'components';
import { useTranslation } from 'react-i18next';

const template = (props) => {
    const { t, i18n } = useTranslation();
    const {
        firstname,
        setFirstname,
        lastname,
        setLastname,
        email,
        setEmail,
        country,
        setCountry,
        company,
        setCompany,
        phoneNumber,
        setPhoneNumber,
        visibleModal = false,
        setVisibleModal
    } = props;
    return (
        <ContentSection>
            <Card className="ant-card-login">
                <WelcomeSection>
                    <WelcomeText>
                        {t('REQUEST_ACCOUNT').toUpperCase()}
                    </WelcomeText>
                </WelcomeSection>
                <InstructionSection>
                    <InstructionText>
                        {t('REQUEST_ENTER_DETAIL')}
                    </InstructionText>
                </InstructionSection>
                <LoginInformationSection className="login-information-section">
                    <Form layout="vertical" style={{ width: '100%' }}>
                        <Form.Item>
                            <Radio>{t('MR')}</Radio>
                            <Radio>{t('MRS')}</Radio>
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '0' }}>
                            <NameSection>
                                <div className="request-account-input-wrapper">
                                    <Form.Item>
                                        <CustomInput
                                            label={t(
                                                'FIRST_NAME'
                                            ).toUpperCase()}
                                            required
                                            // minWidth="270px"
                                            width="100%"
                                            placeholder={t('YOUR_FIRST_NAME')}
                                            value={firstname}
                                            onChange={setFirstname}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="request-account-input-wrapper">
                                    <Form.Item>
                                        <CustomInput
                                            label={t('LAST_NAME').toUpperCase()}
                                            required
                                            // minWidth="270px"
                                            width="100%"
                                            placeholder={t('YOUR_LAST_NAME')}
                                            value={lastname}
                                            onChange={setLastname}
                                        />
                                    </Form.Item>
                                </div>
                            </NameSection>
                        </Form.Item>
                        <Form.Item>
                            <CustomInput
                                label={t('EMAIL_ADDRESS').toUpperCase()}
                                required
                                // minWidth="290px"
                                width="100%"
                                placeholder={t('ENTER_YOUR_EMAIL_ADDRESS')}
                                value={email}
                                onChange={setEmail}
                            />
                        </Form.Item>
                        <Form.Item style={{ marginBottom: '0' }}>
                            <CountryPhoneSection>
                                {/* <CountrySection> */}
                                <div className="request-account-input-wrapper">
                                    <Form.Item>
                                        <CustomInput
                                            label={t('COUNTRY').toUpperCase()}
                                            required
                                            width="100%"
                                            placeholder={t('SELECT_COUNTRY')}
                                            value={country}
                                            onChange={setCountry}
                                        />
                                    </Form.Item>
                                </div>
                                {/* </CountrySection> */}
                                {/* <PhoneSection> */}
                                <div className="request-account-input-wrapper">
                                    <Form.Item>
                                        <CustomInput
                                            label={t(
                                                'PHONE_NUMBER'
                                            ).toUpperCase()}
                                            required
                                            width="100%"
                                            placeholder={t('PHONE_NUMBER')}
                                            value={phoneNumber}
                                            onChange={setPhoneNumber}
                                        />
                                    </Form.Item>
                                </div>
                                {/* </PhoneSection> */}
                            </CountryPhoneSection>
                        </Form.Item>
                        <Form.Item>
                            <CustomInput
                                label={t('COMPANY').toUpperCase()}
                                optional
                                minWidth="290px"
                                width="100%"
                                placeholder={t('COMPANY_NAME')}
                                value={company}
                                onChange={setCompany}
                            />
                        </Form.Item>
                        <Form.Item>
                            <StyledButton onClick={() => setVisibleModal(true)}>
                                <SubmitButtonText>
                                    {t('SUBMIT_REQUEST').toUpperCase()}
                                </SubmitButtonText>
                            </StyledButton>
                        </Form.Item>
                    </Form>
                </LoginInformationSection>
            </Card>
            <Popup
                cancelModal={() => {
                    setVisibleModal(false);
                }}
                onOk={() => {
                    setVisibleModal(false);
                }}
                visibleModal={visibleModal}
                successText={t('THANK_YOU_REGISTER').toUpperCase()}
                successSecondLine={t('CONTACT_SOON')}
                buttonTitle={t('OK').toUpperCase()}
            />
        </ContentSection>
    );
};

const CountryPhoneSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
`;

const NameSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
`;

const ContentSection = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    .ant-card-login {
        border-radius: 8px;
        width: 60vw;
        min-width: 620px;
        max-width: 860px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .ant-radio-wrapper span {
        font-family: AvenirNext-DemiBold;
        font-size: 11px;
        text-transform: uppercase;
        color: #6c6f76;
    }
    /* .ant-form-item-control {
        min-width: 550px;
    } */
    /* @media (max-width: 1366px) {
        .ant-form-item-control {
            min-width: 400px;
        }
    }
    .ant-form-vertical .ant-form-item-label > label {
        width: 100%;
    } */
`;

const StyledButton = styled.button`
    width: 100%;
    background-color: #19c0ff;
    border-radius: 2px;
    border: none;
    height: 50px;
`;

const SubmitButtonText = styled.span`
    color: #ffffff;
    font-family: AvenirNext-DemiBold;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 16px;
    text-align: center;
`;

const WelcomeSection = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
`;

const WelcomeText = styled.span`
    font-size: 20px;
    letter-spacing: 1.5px;
    color: #0e0e12;
    font-family: AvenirNext-DemiBold;
    font-size: 24px;
    letter-spacing: 2px;
    line-height: 32px;
    text-align: center;
`;

const InstructionSection = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
`;

const InstructionText = styled.span`
    color: #65686f;
    font-family: Lato;
    font-size: 13px;
    line-height: 24px;
`;

const LoginInformationSection = styled.div`
    display: flex;
    justify-content: center;
    /* .ant-checkbox-input {
        border-radius: 7px;
    } */
    .request-account-input-wrapper {
        width: 47%;
    }
    /* @media (max-width: 1680px) {
        .request-account-input-wrapper {
            width: 290px;
        }
    } */
    /* @media (max-width: 1024px) {
        .request-account-input-wrapper {
            width: 100%;
        }
    } */
`;

const LabelText = styled.span`
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.7px;
    line-height: 12px;
    text-align: left;
`;

const PasswordLabelSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 29.6875vw;
    .forgot-password-button {
        float: right;
    }
    .forgot-password-text {
        text-align: right;
    }
    /* Specific for iPhone 12 Pro Max and  devices have the view port width above */
    @media (max-width: 700px) {
        .forgot-password-button {
            float: left;
            width: 29.6875vw;
            padding-left: 0;
            align-items: flex-start;
            display: flex;
        }
        .forgot-password-text {
            text-align: left;
        }
    }
`;

const ForgotPasswordSection = styled.button`
    background-color: Transparent;
    background-repeat: no-repeat;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    border: none;
    padding-right: 0;
`;

const ForgotPasswordText = styled.span`
    font-family: AvenirNext-DemiBold;

    color: #65686f;
    font-size: 11px;
    line-height: 14px;
    text-align: right;
    position: relative;
`;

const inputStyle = {
    height: '50px',
    border: '1px solid rgba(36, 36, 45, 0.15)',
    borderRadius: '3px'
};
export { template };
