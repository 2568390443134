import React from 'react';
import './component.css';

// Antd custom components
import { InputComponent as Input } from './Antd/Input/InputComponent';
import EmptyComponent from './Antd/Empty';
import Text from './Antd/Text/Text';
import TagComponent from './Antd/Tag/Tag';
import SliderComponent from './Antd/Slider/Slider';
import AutoComplete from './Antd/AutoComplete';
import CheckboxComponent from './Antd/Checkbox/Checkbox';
import TextAreaComponent from './Antd/TextArea/TextArea';
import DropDownInput from './Antd/DropDownInput/DropDownInput';
import SearchInput from './Antd/SearchInput/SearchInput';
import UploadComponent from './Antd/Upload/Upload';
import UploadAvatar from './Antd/Upload/UploadAvatar';
import TabsComponent from './Antd/Tabs/Tabs';
import Calendar from './Antd/Calendar/Calendar';
import BigCalendar from './Antd/Calendar/BigCalendar';

// Button
import Button from './Antd/Button/Button';
import ButtonCustom from './Antd/Button';
import PrimaryButton from './Antd/Button/PrimaryButton';
import SecondaryButton from './Antd/Button/SecondaryButton';

// Header
import Header from './Cards/Header';
import HeaderTag from './Cards/HeaderTag';
import Popup from './Popup/Popup';

// Cards
import LoginCard from './Cards/LoginCard/LoginCard';
import { RequestAccountCard } from './Cards/RequestAccountCard/RequestAccountCard';
import FlightStatusCard from './Cards/FlightStatusCard/FlightStatusCard';
import FlightInformation from './Cards/FlightStatusCard/FlightInformation';
import BookingStatusCard from './Cards/FlightStatusCard/BookingStatusCard';
import EmptyLegFlightDetailStatusCard from './Cards/FlightStatusCard/EmptyLegFlightDetailStatusCard';
import FlightDetailStatusCard from './Cards/FlightStatusCard/FlightDetailStatusCard';
import AircraftTypeCard from './Cards/AircraftTypeCard/AircraftTypeCard';
import ServiceItem from './Cards/ServiceItem/ServiceItem';
import SimpleMap from './Cards/Map/Map';
import SimpleMap2 from './Cards/Map/Map2';
import DashboardMap from './Cards/Map/DashboardMap';
import { PassengerInfoContainer, FlightNote, PackageDetailCard } from './Cards';
import { InvoicePaymentCard } from './Cards';

// Commons
import Booker from './Commons/Booker';
import BookingStatus from './Commons/BookingStatus';
import FlightInfo from './Commons/FlightInfo';
import JetInfo from './Commons/JetInfo';
import PaymentStatus from './Commons/PaymentStatus';
import SearchBooking from './Commons/SearchBooking';
import PriceRange from './Commons/PriceRange';
import TotalPayout from './Commons/TotalPayout';
import PassengersInfo from './Commons/PassengersInfo';
import ConnectFlight from './Commons/ConnectFlight';
import CrewInfo from './Commons/CrewInfo';
import AircraftInfo from './Commons/AircraftInfo';
import FlightNotes from './Commons/FlightNotes';

const localeEmptyTable = (description) => {
    return {
        emptyText: (
            <EmptyComponent
                description={description}
                iconSize={28}
                padding="10px"
                styleContainer={{ marginTop: 0 }}
            />
        )
    }
};

export {
    localeEmptyTable,
    BigCalendar,
    Calendar,
    DashboardMap,
    PackageDetailCard,
    FlightInformation,
    FlightNote,
    PassengerInfoContainer,
    TabsComponent as Tabs,
    PriceRange,
    Input,
    AircraftTypeCard,
    SimpleMap as Map,
    SimpleMap2 as Map2,
    ServiceItem,
    FlightStatusCard,
    BookingStatusCard,
    FlightDetailStatusCard,
    EmptyLegFlightDetailStatusCard,
    TextAreaComponent as TextArea,
    CheckboxComponent as Checkbox,
    AutoComplete,
    SliderComponent as Slider,
    TagComponent as Tag,
    DropDownInput,
    SearchInput,
    Text,
    LoginCard,
    RequestAccountCard,
    Button,
    ButtonCustom,
    HeaderTag,
    Header,
    Popup,
    UploadComponent,
    UploadAvatar,
    EmptyComponent,
    PrimaryButton,
    SecondaryButton,
    Booker,
    BookingStatus,
    FlightInfo,
    JetInfo,
    InvoicePaymentCard,
    PaymentStatus,
    SearchBooking,
    TotalPayout,
    PassengersInfo,
    ConnectFlight,
    CrewInfo,
    AircraftInfo,
    FlightNotes
};
